import React from "react";
import MentorProfile from "../components/Mentoring/MentorProfile";
import MentorProfileNavbar from "../components/Navbars/MentorProfileNavbar";


const MentorProfilePage = () => {
    return (
        <>  
            <MentorProfileNavbar />
            <MentorProfile />
        </>
    );
};
export default MentorProfilePage;
